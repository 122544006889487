@import '../../styles/variables';

.post-fault {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-top: 3.375em;
    div {
        color: white;
    }

    .fault-score {
        border: 2px solid orangered;
        font-size: 5em;
        font-weight: bold;
        font-style: italic;
        margin-top: 0.1em;
        margin-bottom: 0.5em;
        min-width: 5em;
        padding: 0 1em;
        line-height: 1em;
    }

    .skill-rating {
        align-items: flex-end;
    }

    .rating-column > div[class*='col'] {
        border-right: 2px solid white;
        justify-content: space-around;
        align-items: center;
        height: 90%;
    }

    .rating-column div[class*='col']:last-of-type {
        border-right: none;
    }

    .rating.row div[class*='col'] {
        border-right: 0;
        height: 100%;
    }

    .rating.row div[class*='col']:first-of-type {
        flex: 4;
        align-items: baseline;
        text-transform: uppercase;
        letter-spacing: -2px;
    }

    .temp-text {
        font-size: 1.5em;
        text-align: left;
        display: flex;
        align-items: center;
    }

    .row {
        flex: 1;
        text-align: center;
        justify-content: center;

        .breakdown {
            flex: 10;
            width: 100%;
            font-size: 1.25em;

            &.title {
                flex: 1;
                font-size: 1.5em;
                justify-content: flex-start;
                padding-bottom: 1em;

                > span {
                    border-bottom: 2px solid white;
                }
            }

            .row {
                text-align: start;
                align-items: flex-start;

                & :nth-child(2) {
                    text-align: right;
                }
            }
        }

        .rating {
            border: 1px solid white;
            justify-content: space-between;
            font-size: 2em;
            padding: 0.3em 0;
            margin: 0.5em 0;
            width: 80%;
        }

        .rating:first-of-type {
            margin-top: 0;
        }

        .rating:last-of-type {
            margin-bottom: 0;
        }
    }

    > .row:last-of-type {
        display: flex;
        justify-content: center;
    }

    .row.rating-column {
        width: 90%;
        display: flex;
        flex: 6;

        div[class*='col'] {
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
    }

    .title {
        padding: 0;
        letter-spacing: 0;
        font-size: 3em;
    }

    .total {
        border-top: 1px solid white;
        flex: 0.25;
    }

    .warning {
        color: $simutech-orange-hex;
    }
}
