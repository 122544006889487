@import '../../styles/variables';

.work-order-page {
    height: 100vh;
    position: relative;

    &.react-draggable {
        height: unset;
    }
    .container {
        .row {
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
        }

        .col {
            display: flex;
            height: 100%;
            justify-content: center;
            align-items: center;
        }
    }
    .top-z-index {
        position: relative;
        z-index: 1;
    }
    .center-all {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 85%;

        &.row {
            flex-direction: row;
        }

        &.column {
            flex-direction: column;
        }
    }
}
