@import '../../node_modules/bootstrap/scss/functions';
@import '../../node_modules/bootstrap/scss/_variables.scss';
@import '../../node_modules/bootstrap/scss/mixins/_breakpoints';
@import '../styles/variables.scss';
@import '../styles/mixins.scss';

body {
    font-family: 'SourceSansPro-Regular', sans-serif;
    &.home {
        &.TS {
            background: url('../assets/images/ts-home-bg.png') no-repeat center center fixed;
            background-size: cover;
            @include scrollbars(0.5em, $simutech-blue-rgba, rgba(255, 255, 255, 1));
        }
        &.LL {
            background: url('../assets/images/ll-home-bg.png') no-repeat center center fixed;
            background-size: cover;
            @include scrollbars(0.5em, $simutech-orange-rgba, #9c4a32);
        }
        &.alt1 {
            background: url('../assets/images/ll-home-bg-alt1.jpg') no-repeat center center fixed;
            background-size: cover;
        }
        &.alt2 {
            background: url('../assets/images/ll-home-bg-alt2.jpg') no-repeat center center fixed;
            background-size: cover;
        }
        &.alt3 {
            background: url('../assets/images/ll-home-bg-alt3.jpg') no-repeat center center fixed;
            background-size: cover;
        }
    }
    //width: 100vw;
    overflow-x: hidden;
}
.logo {
    padding: 1.5em;
    img {
        width: 284px;
        height: auto;
        max-width: 100%;
        max-height: 100%;
        display: block;
    }
}
.module-title {
    color: #fff;
    padding: 0.5em 0;
    font-weight: 600;
    font-size: 2.75em;
    text-transform: uppercase;
    letter-spacing: 4px;
}

.module-description {
    color: #fff;
}

.lrs-status {
    display: inline-block;
    width: 1rem;
    margin-right: 0.5em;
    color: white;
    svg {
        display: block;
        margin: auto;
    }
}

.accordion {
    background-color: transparent !important;
}

.accordion-item {
    background-color: transparent !important;
    overflow-y: auto;
    overflow-x: hidden;
}

.accordion-header {
    background-color: transparent !important;
    color: $simutech-orange-rgba !important;
}

.accordion-button {
    background-color: transparent !important;
    border: none !important;
    border-top: 1px solid #fff !important;
    font-size: 1.25rem !important;
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
    &:hover {
        color: $simutech-orange-rgba !important;
        color: rgba(255, 255, 255, 0.35) !important;
    }
    box-shadow: none !important;
    &::after {
        background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
    }
}

.accordion-body {
    @include scrollbars(0.5em, $simutech-orange-hex, rgba(255, 255, 255, 0.1));
    background-color: transparent !important;
    max-height: 19rem !important;
    border: none !important;
    color: #fff !important;
    padding-left: 2.15rem !important;
    padding-right: 0.75rem !important;
    padding-top: 0.25rem !important;
    overflow-y: auto;
    .description.disabled {
        color: rgba(255, 255, 255, 0.35) !important;
    }
}

.lesson-link {
    &:not(:last-child) {
        margin-bottom: 1rem;
    }
    a {
        font-size: 1.1rem !important;
        &:hover:not(.disabled) {
            color: $simutech-orange-rgba !important;
            cursor: pointer;
        }
        &.disabled {
            pointer-events: none;
            color: rgba(255, 255, 255, 0.35) !important;
        }
    }
}

@include media-breakpoint-up(xxl) {
    // >1400px --Bootstrap breakpoint

    .lrs-status {
        width: 1.25rem;
    }
    .accordion-body {
        padding-left: 2.6875rem !important;
    }
    .accordion-button {
        font-size: 1.75rem !important;
    }
    .lesson-link {
        a {
            font-size: $base-font-size * 1.2 !important;
        }
    }
}

@media (min-width: $bp-xlarge) {
    // >1920px --Custom breakpoint

    .lrs-status {
        width: 1.5rem;
    }
    .accordion-body {
        padding-left: 3.225rem !important;
    }
    .accordion-button {
        font-size: 2rem !important;
    }
    .lesson-link {
        a {
            font-size: $base-font-size * 1.3 !important;
        }
    }
}

.module-content {
    @extend .min-specs;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 200;
    background-color: $simutech-blue-hex;
    height: 100vh;

    &.homepage {
        display: block;
        height: 100vh;
    }
    &.homepage {
        background-color: unset;
    }
}

.home {
    background: url('../assets/icons/home.svg') no-repeat;

    &:hover {
        background: url('../assets/icons/home-hover.svg') no-repeat;
    }
    &:active {
        background: url('../assets/icons/home-active.svg') no-repeat;
    }
    &:disabled,
    &.disabled {
        background: url('../assets/icons/home-disabled.svg') no-repeat;
    }
}

.help {
    background: url('../assets/icons/help.svg') no-repeat;

    &:hover {
        background: url('../assets/icons/help-hover.svg') no-repeat;
    }
    &:active {
        background: url('../assets/icons/help-active.svg') no-repeat;
    }
}

.door {
    background: url('../assets/icons/door.svg') no-repeat;

    &:hover {
        background: url('../assets/icons/door-hover.svg') no-repeat;
    }
    &:active {
        background: url('../assets/icons/door-active.svg') no-repeat;
    }
}

.door-black {
    background: url('../assets/icons/door-black.svg') no-repeat;
    display: inline-block;
    margin: 0 0.125em;
    min-width: 1.125em;
    min-height: 1em;
}

.help-dark {
    background: url('../assets/icons/help-dark.svg') no-repeat;
    display: inline-block;
    margin: 0 0.125em;
    min-width: 1.25em;
    min-height: 1.125em;
}

.exit {
    background: url('../assets/icons/exit.svg') no-repeat;

    &:hover {
        background: url('../assets/icons/exit-hover.svg') no-repeat;
    }
    &:active {
        background: url('../assets/icons/exit-active.svg') no-repeat;
    }
}

.settings {
    background: url('../assets/icons/settings.svg') no-repeat;

    &:hover {
        background: url('../assets/icons/settings-hover.svg') no-repeat;
    }
    &:active {
        background: url('../assets/icons/settings-active.svg') no-repeat;
    }
}
