@import '../../node_modules/bootstrap/scss/functions';
@import '../../node_modules/bootstrap/scss/_variables.scss';
@import '../../node_modules/bootstrap/scss/mixins/_breakpoints';
@import './variables';

@font-face {
    font-family: 'SourceSansPro-Regular';
    src: url('../assets/fonts/SourceSansPro-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'SourceSansPro-Bold';
    src: url('../assets/fonts/SourceSansPro-Bold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'SourceSansPro-SemiBold';
    src: url('../assets/fonts/SourceSansPro-SemiBold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'SourceSansPro-Light';
    src: url('../assets/fonts/SourceSansPro-Light.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

html {
    min-height: 100%;
}

@font-face {
    font-family: 'SourceSansPro-Bold';
    src: url('../assets/fonts/SourceSansPro-Bold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'SourceSansPro-SemiBold';
    src: url('../assets/fonts/SourceSansPro-SemiBold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'SourceSansPro-Light';
    src: url('../assets/fonts/SourceSansPro-Light.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

body {
    margin: 0;
    font-size: $base-font-size;
    line-height: $base-line-height;
    font-family: 'SourceSansPro-Regular', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
        'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important;
    background-color: $simutech-blue-rgba;
    @media #{$mq-xxlarge} {
        font-size: $base-font-size * 1.2 !important;
    }

    @media #{$mq-xxxlarge} {
        font-size: $base-font-size * 1.3 !important;
    }
    ::-moz-selection {
        background: $simutech-orange-rgba;
    }
    ::selection {
        background: $simutech-orange-rgba;
    }
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

#root {
    height: 100%;
}
