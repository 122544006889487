@import '../../styles/variables';

.pre-fault {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    position: relative;
    color: white;

    .overallScore {
        border: 2px solid $simutech-orange-rgba-active;
        font-size: 5em;
        font-weight: bold;
        font-style: italic;
        margin-top: 0.1em;
        margin-bottom: 0.5em;
        width: 5em;
        line-height: 1em;
        text-align: center;
    }

    .temp-text {
        font-size: 1.75em;
        text-align: left;
        display: flex;
        align-items: center;
    }

    .userOverview {
        display: flex;
        flex-direction: row;
        width: 100%;
        padding: 0 7.5em;

        > .col {
            display: flex;
            justify-content: center;
        }

        .userLevel,
        .userStats {
            flex-direction: column;
            flex-wrap: nowrap;
        }
        .userLevel {
            align-items: flex-start;
        }

        .userStats {
            align-items: flex-end;
            text-align: end;
            justify-content: space-around;
            padding-right: 0;
            flex-wrap: nowrap;
            &.customTest {
                max-width: 50%;
                padding: 1em;
                align-items: center;
                justify-items: flex-start;
            }
        }

        .levels {
            width: 80%;
            margin-top: 1em;
            margin-bottom: 0.5em;
            > .col:first-of-type {
                padding-left: 0;
            }

            .level {
                font-size: 0.875em;
            }
        }

        .userTitle,
        .userStat {
            font-size: 1.125em;
            font-weight: bold;
        }

        .userStat {
            width: 50%;

            .statTitle {
                align-items: flex-start;
                display: flex;
            }

            .stat {
                align-items: flex-start;
                display: flex;
                flex: 0.3;
                color: $simutech-orange-rgba-active;
                padding-right: 0;
            }
        }
        .customTest .userStat {
            flex: 0.5;            
        }
    }

    .row {
        justify-content: center;
    }

    .trends {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        padding: 0 7.5em;

        .trendBox {
            display: flex;
            flex-direction: column;
            border: 2px solid $simutech-orange-rgba-active;
            margin: 1em 2em;

            &:first-of-type {
                margin-left: 0;
            }

            &:last-of-type {
                margin-right: 0;
            }

            .trendType {
                font-size: 2em;
                font-weight: bold;
                text-transform: uppercase;
                justify-content: flex-start;
                padding-left: 0.5em;
                margin-top: 0.25em;
                margin-bottom: -0.25em;
            }

            .innerTrendBox {
                background-color: rgba(255, 255, 255, 0.1);
                display: flex;
                justify-content: center;
                margin: 1em 0.25em;
                padding: 0.5em 0;

                .trendTitle {
                    font-size: 0.875em;
                }

                .trendPercentage {
                    font-size: 2.25em;
                    top: 0.25em;
                    position: relative;
                }

                > .col {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                }
            }
        }
    }

    .title {
        padding: 0 0 0.5em 0;
        letter-spacing: 0;
        font-size: 2.5em;
    }

    .fa-arrow-right,
    .fa-minus {
        color: $simutech-orange-hex-active;
    }

    .notYetReached {
        .level {
            color: rgba(255, 255, 255, 0.4);
        }

        .fa-star {
            color: rgba(241, 93, 44, 0.4);
        }
    }

    .reached {
        .level {
            color: rgba(255, 255, 255, 1);
        }

        .fa-star {
            color: $simutech-orange-hex-active;
        }
    }
}
