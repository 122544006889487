@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';

$panel-size: 21.875em;

.interactive {
    pointer-events: auto;
}
.click-through {
    pointer-events: none;
}

.learning-area {
    @extend .click-through;
    position: absolute;
    display: flex;
    flex-direction: row;
    right: 0px;
    top: 3.375em;
    bottom: 3.688em;
    width: 100%;

    .button-bar {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 1.25em 0;

        .button {
            font-family: 'SourceSansPro-SemiBold', 'Segoe UI', 'Roboto', 'Ubuntu', 'Cantarell', 'Fira Sans',
                'Droid Sans', 'Helvetica Neue', sans-serif !important;
            display: flex;
            justify-content: center;
            align-items: center;
            text-transform: uppercase;
            height: 2em;
            min-width: 8em;
            font-size: 1.25em;
            margin: 0.25em 0.25em;
            border-radius: 0;
            padding: 0 0.5em;
            line-height: 1em;
            text-decoration: none;
            transition: all 0.25s ease;
            &:disabled {
                cursor: default;
            }
            &.primary {
                border: 1px solid white;
                background-color: $simutech-blue-rgba-90;
                color: white;
                &:not(:disabled):not(.disabled):hover {
                    background-color: white;
                    color: $simutech-blue-rgba-90;
                    border: 1px solid $simutech-blue-rgba-90;
                }
            }

            &.secondary {
                background-color: white;
                color: $simutech-blue-rgba-90;
                border: 1px solid $simutech-blue-rgba-90;
                &:not(:disabled):not(.disabled):hover {
                    background-color: $simutech-blue-rgba-90;
                    color: white;
                    border: 1px solid white;
                }
            }
        }
    }
}

.media-area {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    width: 100%;
    z-index: 1;

    &-container {
        @extend .interactive;
        position: relative;
        display: flex;
        justify-content: space-around;
        align-items: center;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.75);
    }

    .player-wrapper {
        position: relative;
        display: flex;
        flex-flow: row-reverse wrap;
        justify-content: center;
        align-content: center;
        width: 90%;

        @media #{$mq-xxlarge} {
            width: 75%;
        }
        @media #{$mq-xxxlarge} {
            width: 65%;
        }
        img {
            max-height: 650px;
        }
    }

    video::-webkit-media-controls-fullscreen-button {
        display: none;
    }

    .close-button {
        @extend .interactive;
        position: absolute;
        z-index: 1;
        top: 0.75em;
        right: 0;
        width: 1.563em;
        height: 1.563em;
        margin: 1em;
        background-image: url('../../assets/icons/exit.svg');
        &:hover {
            cursor: pointer;
        }
    }
}

.player-background {
    position: relative;
    width: 100%;
    height: 100%;
    top: 0;
}

.learning-panel {
    @extend .interactive;
    position: relative;
    left: -$panel-size;
    width: $panel-size;
    min-width: $panel-size;
    height: 100%;
    margin: 0;
    padding: 0;
    border: 0;
    background-color: white;
    z-index: 19;

    .panel-nav {
        position: absolute;
        right: 1.25em;
        margin: 0;
        padding: 0.35em;
        bottom: 0;
        width: 100%;
        //height: 40px;
        background-color: #ffffff;
    }

    .navbuttons {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        margin-left: auto;
        margin-right: auto;

        p {
            position: relative;
            font-size: 1em;
            text-align: center;
            color: $simutech-blue-rgba-90;
            margin: 0px;
        }

        .btn {
            border: 0;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: left;
            background-color: transparent;
            border-color: transparent;
            box-shadow: none;

            &:not(:disabled):not(.disabled):active {
                background-color: transparent;
                border-color: transparent;
                outline: none !important;
                box-shadow: none;
            }
        }

        button {
            width: 2.5em;
            height: 2.5em;
            margin: 0 0.625em;
        }

        $icons-url: '../../assets/icons/';

        .next-panel {
            background-image: url('#{$icons-url}next-panel.svg');
            &:disabled,
            &.disabled {
                background-image: url('#{$icons-url}next-panel-disabled.svg');
            }
        }

        .previous-panel {
            background-image: url('#{$icons-url}previous-panel.svg');
            &:disabled,
            &.disabled {
                background-image: url('#{$icons-url}previous-panel-disabled.svg');
            }
        }
    }
}

.learning-content {
    //@include scrollbars(0.5em, rgba(0, 52, 98, 0.45), $light-grey);
    position: relative;
    color: $simutech-blue-rgba-90;
    height: 100%;
    margin: 0;
    padding: 1.5em 1.5em 3.5em 1.5em;
    overflow: auto;
    background-color: white;
}

.panel-switch {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 100%;
    bottom: 100%;
    width: 2.5em;
    height: 2.813em;
    background-color: white;
    box-shadow: 4px 4px 6px -4px rgba(0, 0, 0, 0.47);
    z-index: 20;
    cursor: pointer;
    & svg {
        position: relative;
        width: 1.25em;
        height: 1.25em;
    }
}

.panel-title {
    font-family: 'SourceSansPro-Bold';
    margin: 1em 0;
}

@keyframes hide-learning-panel {
    0% {
        left: 0px;
    }
    100% {
        left: -$panel-size;
    }
}

@keyframes show-learning-panel {
    0% {
        left: -$panel-size;
    }
    100% {
        left: 0px;
    }
}

@keyframes grow-video-area {
    0% {
        width: 73.75%;
        left: $panel-size;
    }
    100% {
        width: 100%;
        left: 0px;
    }
}

@keyframes shrink-video-area {
    0% {
        width: 100%;
        left: 0px;
    }
    100% {
        width: 73.75%;
        left: $panel-size;
    }
}

@keyframes hide-videoplayer {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@keyframes show-videoplayer {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
