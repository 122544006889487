@import '../styles/variables.scss';

.SimutechModal {
    .LDL-table {
        table {
            width: 100%;
            border-collapse: collapse;
        }

        table,
        th,
        td {
            border: 1px solid black;
        }

        th {
            font-family: 'SourceSansPro-Semibold';
            text-transform: uppercase;
        }

        td,
        th {
            padding: 0.05em 1.5em;
            vertical-align: initial;
            color: black;
        }
    }

    .modal-body {
        font-size: 1.25rem;
        color: $simutech-blue-hex;
        font-family: 'SourceSansPro-Light';
        display: flex;
        flex-direction: column;
        padding: 0 3em;
        min-height: 9.375em;
        justify-content: center;
    }

    .modal-content {
        border-radius: 0;
    }

    .modal-title {
        font-family: 'SourceSansPro-Bold';
        font-size: 2.25rem;
        color: $simutech-blue-rgba;
        text-align: center;
        width: 100%;
    }
    .modal-header,
    .modal-footer {
        justify-content: center;
        border: 0;
    }
}
