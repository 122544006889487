$simutech-blue-rgba-90: rgba(0, 52, 98, 0.9);
$simutech-blue-rgba: rgba(0, 52, 98, 1);
$simutech-blue-hex: #003462;
$simutech-blue-highlight: #e7e9f0;
$simutech-orange-hex: #f47e56;
$simutech-orange-rgba: rgba(244, 126, 86, 1);
$simutech-orange-rgba-90: rgba(244, 126, 86, 0.9);
$simutech-orange-hex-active: #f15d2c;
$simutech-orange-rgba-active: rgba(241, 93, 44, 1);
$light-grey: rgba(241, 242, 242, 1);

// Font-Size
$base-font-size: 1em;

// Line-Height
$base-line-height: 1.5;
$header-line-height: 1.25;

// Breakpoints
$bp-small: 48em; // 768px
$bp-medium: 64em; // 1024px
$bp-large: 85.375em; // 1366px
$bp-xlarge: 120em; // 1920px
$bp-xxlarge: 140em;
$bp-xxxlarge: 160em; // 2560px

// Media Queries
$mq-small: '(min-width: #{$bp-small})';
$mq-medium: '(min-width: #{$bp-medium})';
$mq-large: '(min-width: #{$bp-large})';
$mq-xlarge: '(min-width: #{$bp-xlarge})';
$mq-xxlarge: '(min-width: #{$bp-xlarge})';
$mq-xxxlarge: '(min-width: #{$bp-xxlarge})';
$mq-retina: '(-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi)';

.min-specs {
    min-width: $bp-large;
    min-height: $bp-small;
}
